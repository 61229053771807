export default class Alert {
	static show(params) {
		let mc = jQuery("#template-alert").clone();
		mc.attr("id", "alert" + Alert.ENUM++);
		jQuery("body").css("overflow", "hidden");
		jQuery("html").css("overflow", "hidden");
		jQuery(".js-atitle", mc).html(params.title);
		jQuery(".js-atext", mc).html(params.text);

		if (params.buttonOk) {
			jQuery(".js-aok", mc).html(params.buttonOk);
		} else {
			jQuery(".js-aok", mc).hide();
			jQuery(".js-padder", mc).hide();
		}

		if (params.buttonKo) {
			jQuery(".js-acancel", mc).html(params.buttonKo);
		} else {
			jQuery(".js-acancel", mc).hide();
			jQuery(".js-padder", mc).hide();
		}


		jQuery(".js-aok", mc).on("click", jQuery.proxy(Alert.Okay, Alert, mc, params));


		jQuery(".js-acancel", mc).on("click", jQuery.proxy(Alert.Ko, Alert, mc, params));


		jQuery("body").append(mc);
	}

	static Okay(mc, params) {
		mc.remove();
		if (params.callbackOk) {
			params.callbackOk.call(null, true);
		}
	}
	static Ko(mc, params) {
		mc.remove();
		if (params.callbackKo) {
			params.callbackKo.call(null, false);
		}
	}
}

Alert.ENUM = 0;