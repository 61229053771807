import Provider from "./Provider.js";
import "../scss/styles.scss";
import Alert from "./Alert.js";
import Fellow from "./Fellow.js";
import Ambienti from "./Ambienti.js";
import CameraPhoto, { FACING_MODES, IMAGE_TYPES } from 'jslib-html5-camera-photo';


export default class Main {
	constructor() {
		this.counter = 0;
		this.fellows = new Array();
		this.senza = false;
		this.mobile = false;
		this.ios = false;
		this.contaCheat = 0;
		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
			this.mobile = true;
			if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
				this.ios = true;
			}

		}

		if (this.mobile) {
			if (this.ios) {
				jQuery(".js-pixel").attr("href", "https://apps.apple.com/us/app/pixel-manager-football-2020/id1454509064?l=it&ls=1");
			} else {
				jQuery(".js-pixel").attr("href", "https://play.google.com/store/apps/details?id=com.pixel.manager.football.edition");
			}
		} else {
			jQuery(".js-pixel").attr("href", "https://pixelmanagerfootball.com/");
		}

	}
	render() {
		this.cookiebar();

		// Sceglie lo sfondo
		let callPage = document.querySelector(".page-call");
		if (callPage) {
			this.settaAmbiente();
			this.initZuumCall();
			if (this.mobile) {
				//jQuery(window).scrollTop(100);
			}
		}

		let callGrazie = document.querySelector(".page-tnx");
		if (callGrazie) {
			this.checkGrazie();
		}

	}

	settaAmbiente() {
		Ambienti.settaAmbiente();
	}

	cookiebar() {
		$.cookieBar({
			acceptText: "Accetto",
			domain: ".zuum.one",
			message: "Questo sito utilizza cookie di profilazione, anche di terze parti, per fornirti servizi e pubblicità in linea con le tue preferenze. Se chiudi questo banner o prosegui con la navigazione ne accetti l'utilizzo. Per maggiori informazioni su come modificare il consenso e le impostazioni dei browser supportati leggi <a style='text-decoration:underline;padding:0 0' href='./cookies-policy.html'>l'informativa</a>."
		});
	}

	initZuumCall() {
		//let start = document.querySelector(".js-start");
		//start.addEventListener("click", this.openWebcam.bind(this))

		this.provider = new Provider();
		this.provider.init(window.GlobalProvider);
		jQuery(".js-start").on("click", this.checkWebcam.bind(this));
		// this.openWebcam();
		// this.addPartecipants();
	}



	checkWebcam() {

		jQuery(".js-unkeep").hide();
		jQuery(".js-keep").addClass("disabled");
		jQuery(".js-start").off("click");
		// Controlla se l'utente mongolo molto attento alla sua privacy ha per caso disabilitato le permission
		//console.log(navigator);

		try {

			navigator.permissions.query({
				name: 'camera'
			})
				.then((permissionObj) => {
					if (permissionObj.state == "denied") {
						Alert.show({
							title: "Attenzione!",
							text: "Probabilmente la preoccupazione di preservare la tua privacy ha fatto sì che precedentemente non concedessi alla pagina i diritti di accesso alla webcam.<br>Se adesso hai cambiato idea devi purtroppo abilitarli manualmente.",
							buttonOk: "Spiegami come fare",
							buttonKo: "Continua senza",
							callbackOk: jQuery.proxy(this.spiega, this),
							callbackKo: jQuery.proxy(this.senzaWebcam, this)
						});
					} else {
						this.openWebcam();
					}
				})
				.catch((error) => {
					if (("" + error).indexOf("PermissionName") >= 0) {
						this.openWebcam();
					} else {
						Alert.show({
							title: "Attenzione!",
							text: "Non rilevo nessuna webcam oppure la webcam è in uso da un'altra applicazione.<br>Funziona tutto lo stesso, ma è un po' meno bello.",
							buttonOk: "Okay",
							callbackOk: jQuery.proxy(this.senzaWebcam, this),
						});
					}
				});
		} catch (e) {
			this.openWebcam();
		}
	}

	spiega() {
		window.open("https://support.google.com/chrome/answer/114662", "_blank");
		document.location.reload();
	}

	openWebcam() {
		let videoElement = document.getElementById('video');

		this.cameraPhoto = new CameraPhoto(videoElement);
		var ct = this;
		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			this.cameraPhoto.startCamera()
				.then((stream) => {
					ct.conWebcam(stream);
				})
				.catch((error) => {
					console.log(error)
					if ((("" + error).toLowerCase().indexOf("video failed") >= 0) || (("" + error).toLowerCase().indexOf("could not start") >= 0)) {
						Alert.show({
							title: "Attenzione!",
							text: "Non rilevo nessuna webcam oppure la webcam è in uso da un'altra applicazione.<br>Funziona tutto lo stesso, ma è un po' meno bello.",
							buttonOk: "Okay",
							callbackOk: jQuery.proxy(this.senzaWebcam, this),
						});
					} else {
						Alert.show({
							title: "Attenzione!",
							text: "Preoccupato di preservare la tua preziosa privacy hai probabilmente deciso di non autorizzare la pagina ad accede alla webcam.<br>Funziona tutto lo stesso, ma è un po' meno bello.",
							buttonOk: "Okay",
							callbackOk: jQuery.proxy(ct.senzaWebcam, ct)
						});
					}
				});
		} else {
			Alert.show({
				title: "Attenzione!",
				text: "Non rilevo nessuna webcam oppure la webcam è in uso da un'altra applicazione.<br>Funziona tutto lo stesso, ma è un po' meno bello.",
				buttonOk: "Okay",
				callbackOk: jQuery.proxy(this.senzaWebcam, this)
			})
		}

	}

	senzaWebcam() {
		this.senza = true;
		jQuery(".js-name", jQuery(".js-fellow-me")).show();
		this.preAddFellows();
	}


	conWebcam(stream) {
		this.stream = stream;
		var video = document.getElementById('video');
		video.setAttribute("style", "display:block")
		let img = document.querySelector(".js-img");
		img.setAttribute("style", "display:none");
		let placeholder = document.querySelector(".js-place-holder-user");
		let wrapperP = document.querySelector(".js-partecipants-wrapper");
		//video.srcObject = stream;
		//video.play();
		wrapperP.setAttribute("style", "display:flex")
		placeholder.setAttribute("style", "display:none")

		/*let partecipants = document.getElementById("play")
		partecipants.classList.remove("disabled");
		partecipants.addEventListener("click", this.addPartecipants)*/

		//let btnScatta = document.getElementById("snap");
		//btnScatta.classList.remove("disabled");

		jQuery(".js-name", jQuery(".js-fellow-me")).show();
		this.preAddFellows();
	}

	preAddFellows() {
		setTimeout(jQuery.proxy(this.addFellows, this), 1000);
	}
	addFellows() {




		var snd = document.getElementById("callSound");
		if (Main.SOUND) {
			snd.play();
		}

		let fellows = this.provider.getChatFellows();
		let shown = jQuery(".webcam-shown")
		let hidden = jQuery(".hidden-webcam")
		let rnd = this.shuffle([0, 1, 2, 3, 4]);

		for (let x = 0; x < 5; x++) {
			let fellow = new Fellow(jQuery(".js-fellow" + rnd[x], shown), jQuery(".js-fellow" + rnd[x], hidden), fellows[rnd[x]], x, rnd[x], jQuery.proxy(this.fellowEventHandlers, this));
			this.fellows.push(fellow);
			fellow.init();
		}

		jQuery(".js-name", jQuery(".js-fellow-me", shown)).off("click")
		jQuery(".js-name", jQuery(".js-fellow-me", shown)).on("click", jQuery.proxy(this.cheat, this));

	}


	cheat() {
		this.contaCheat++;
		if (this.contaCheat == 3) {
			Main.SOUND = false;
			Fellow.WAIT = 100;
			Fellow.APPEAR = 100;
		}
	}
	fellowEventHandlers(e) {
		if (e.type == "change") {
			let shown = jQuery(".webcam-shown")
			let hidden = jQuery(".hidden-webcam")
			let fl = e.fellow;
			let tmp = []
			for (let x = 0; x < this.fellows.length; x++) {
				if (this.fellows[x].data.id != fl.data.id) {
					tmp.push(this.fellows[x])
				}
			}
			this.fellows = tmp;


			fl.dispose();
			let fellow = this.provider.getChatFellow(fl.data.id, fl.data.cat, this.getAltriId(), e.lNext);
			if (fellow) {
				this.counter--;
				jQuery(".js-btn-partecipants").off("click");
				jQuery(".js-btn-partecipants").addClass("disabled");
				jQuery(".js-scatta").off("click");
				jQuery(".js-scatta").addClass("disabled");

				let fel = new Fellow(jQuery(".js-fellow" + fl.quale, shown), jQuery(".js-fellow" + fl.quale, hidden), fellow, 0, fl.quale, jQuery.proxy(this.fellowEventHandlers, this));
				this.fellows.push(fel);
				fel.init();
			}
		}

		if (e.type == "ready") {
			this.counter++;
			if (this.counter == 5) {
				jQuery(".js-btn-partecipants").removeClass("disabled");
				jQuery(".js-btn-partecipants").on("click", jQuery.proxy(this.altriPartecipanti, this));

				jQuery(".js-scatta").on("click", jQuery.proxy(this.getPictures, this));
				jQuery(".js-scatta").removeClass("disabled");

				for (let x = 0; x < 5; x++) {
					this.fellows[x].clickable();
				}

				// Rende visibile per un attimo il risultato
				jQuery(".hidden-webcam").show();
				setTimeout(jQuery.proxy(this.hideHidden, this), 50);
				this.cheat();

			}
		}
	}

	hideHidden() {
		jQuery(".hidden-webcam").hide();
	}
	getAltriId() {
		let ret = [];
		for (let x = 0; x < this.fellows.length; x++) {
			ret.push(this.fellows[x].data.id);
		}

		return ret;
	}
	altriPartecipanti() {
		for (let x = 0; x < this.fellows.length; x++) {
			this.fellows[x].dispose();
		}
		this.fellows = [];
		this.counter = 0;
		this.addFellows();
		jQuery(".js-btn-partecipants").off("click");
		jQuery(".js-btn-partecipants").addClass("disabled");
		jQuery(".js-scatta").off("click");
		jQuery(".js-scatta").addClass("disabled");


	}

	shuffle(o) {
		for (var j, x, i = o.length; i; j = parseInt(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
		return o;
	};

	addPartecipants() {
		// Solo per inglesi passare "en" al costruttore di Provider
		this.provider = new Provider();
		this.provider.init(window.GlobalProvider);
		let fellows = this.provider.getChatFellows();
		let partecipants = document.querySelectorAll(".js-partecipant");
		let shown = jQuery(".webcam-shown")
		let hidden = jQuery(".hidden-webcam")

		//		for (let i = 0; i < partecipants.length; i++) {
		for (let i = 0; i < 5; i++) {
			//let partecipant = partecipants[i];
			let partecipant = jQuery(".js-fellow" + i, shown).get(0);
			let partecipantHidden = jQuery(".js-fellow" + i, hidden).get(0);
			let name = partecipant.querySelector(".js-name");
			let fellow = fellows[i];
			name.innerHTML = fellow.nick;
			name.setAttribute("style", "display:block")
			partecipant.setAttribute("style", "background-image:url(assets/images/" + fellow.img + ")")
			let ph = document.querySelectorAll(".js-place-holder");
			ph[i].setAttribute("style", "display:none")

			jQuery(".js-name", partecipantHidden).show();
			jQuery(".js-name", partecipantHidden).text(fellow.nick);

		}

		//let rndTextWrapper = document.querySelector(".js-sentence");
		//rndTextWrapper.innerHTML = rndText;
		//let btnScatta = document.getElementById("snap");
		//btnScatta.classList.remove("disabled");

		for (let x = 0; x < 5; x++) {
			let src = jQuery(".js-fellow" + x, shown);
			let trg = jQuery(".js-fellow" + x, hidden);
			trg.attr("style", src.attr("style"));

		}
		hidden.show();
		jQuery(".js-name", jQuery(".js-fellow-me")).show();

	}


	getPictures() {

		jQuery(".js-btn-partecipants").off("click");
		jQuery(".js-btn-partecipants").addClass("disabled");
		jQuery(".js-scatta").off("click");
		jQuery(".js-scatta").addClass("disabled");

		if (!this.senza) {
			jQuery(".js-place-holder", jQuery(".js-fellow-me")).hide();
		}
		var shown = jQuery(".webcam-shown");
		if (this.stream) {
			let sizeFactor = 1;
			let imageType = IMAGE_TYPES.JPG;
			let imageCompression = 1;
			let isImageMirror = true;

			let config = {
				sizeFactor,
				imageType,
				imageCompression,
				isImageMirror
			};

			let dataURL = this.cameraPhoto.getDataUri(config);

			let panel = document.querySelector(".hidden-webcam");
			let me = panel.querySelector(".js-fellow-me");
			let img = me.querySelector(".js-img");
			img.setAttribute("style", "background-image:url(" + dataURL + "); display:block");

			jQuery(".js-fellow-me").css("background-image", "url(" + dataURL + ")");
		}

		if (this.stream) {
			let tracks = this.stream.getTracks();

			tracks.forEach(function (track) {
				track.stop();
			});
			this.stream = null;
		}
		jQuery(".webcam-video").hide();


		let hidden = jQuery(".hidden-webcam")
		shown.hide();


		hidden.show();
		hidden.width(600);
		jQuery("body").css("overflow", "visible");
		jQuery("html").css("overflow", "visible");
		jQuery(".webcam").css("overflow", "visible");


		var mcDom = hidden.get(0);
		html2canvas(mcDom, {
			allowTaint: true,
			backgroundColor: "rgba(0,0,0,0)",
			onrendered: jQuery.proxy(this.onRenderedBis, this)
		});

		//jQuery("body").css("overflow", "hidden");
		//jQuery("html").css("overflow", "hidden");
		jQuery(".webcam").css("overflow", "hidden");

		hidden.hide();
		shown.show();

	}

	onRenderedBis(canvas) {
		this.primoCanvas = canvas;
		var shown = jQuery(".webcam-shown");
		let hidden = jQuery(".hidden-webcam")
		jQuery(".js-grosso").hide();
		jQuery(".js-largo").show();
		hidden.show();
		hidden.width(1200);
		jQuery("body").css("overflow", "visible");
		jQuery("html").css("overflow", "visible");
		jQuery(".webcam").css("overflow", "visible");


		var mcDom = hidden.get(0);
		html2canvas(mcDom, {
			allowTaint: true,
			backgroundColor: "rgba(0,0,0,0)",
			onrendered: jQuery.proxy(this.onRendered, this)
		});

		//jQuery("body").css("overflow", "hidden");
		//jQuery("html").css("overflow", "hidden");
		jQuery(".webcam").css("overflow", "hidden");

		hidden.hide();
		shown.show();


	}

	onRendered(canvas) {

		/*var test = jQuery("<img src='" + canvas.toDataURL("image/png") + "'>");
		jQuery("body").append(test);
		jQuery("body").css("overflow", "visible");
		jQuery("html").css("overflow", "visible");*/

		//this.imageShareFacebook = canvas.toDataURL("image/png");
		this.imageShareFacebook = canvas.toDataURL("image/jpeg", 80);

		//var bmp = canvas.toDataURL("image/png");
		var bmp = this.primoCanvas.toDataURL("image/png");
		//var bmp = this.primoCanvas.toDataURL("image/jpeg", 80);

		jQuery(".js-ugc").css("background-image", "url(" + bmp + ")");
		jQuery(".js-results").show();
		jQuery(".js-results").css("opacity", 0);
		this.setDimensions();
		jQuery(window).on("resize", jQuery.proxy(this.setDimensions, this));
		jQuery(".js-bar-0").hide();

		let shown = jQuery(".webcam-shown")
		TweenMax.to(jQuery(".js-results"), 1, {
			css: {
				opacity: 1
			}
		});
		TweenMax.to(shown, 1, {
			css: {
				opacity: 0
			},
			onComplete: jQuery.proxy(this.removeEditor, this)
		});
	}

	setDimensions() {
		let winw = jQuery(window).width();
		let area = jQuery(".js-results");
		let aw = area.width();
		let ah = area.height() - jQuery(".js-bar-1").height();

		let rif = jQuery(".js-image-base");
		let rifw = rif.width();
		let rifh = rif.height();

		if (winw > 1023) {
			rif.height(ah);
			let perc = rifh / ah;
			rif.width(aw * perc);

			let lCondExit = false;
			let controllo = 0;
			while (!lCondExit) {
				let i = jQuery(".js-ambiente");
				if (i.height() > ah) {
					rif.width(rif.width() - 10);
				} else {
					lCondExit = true;
				}
				controllo++;
				if (controllo > 250) {
					lCondExit = true;
				}
			}
		} else {
			rif.removeAttr("style");
		}

		this.setUgcDimensions();

	}

	setUgcDimensions() {
		let dimandpos = Ambienti.getDimAndPos();
		let ambiente = jQuery(".js-ambiente");

		jQuery(".js-ugc").css({
			top: ambiente.height() * dimandpos.top,
			left: ambiente.width() * dimandpos.left,
			height: ambiente.height() * dimandpos.height,
			width: ambiente.width() * dimandpos.width
		})
	}

	removeEditor() {
		let shown = jQuery(".webcam-shown")
		shown.hide();
		this.showSentence();
		this.initButtonsBar2();

	}

	showSentence() {
		//jQuery(".js-sentence").show();
		this.lastSentence = this.provider.getEmotionalSentence();
		jQuery(".js-sentence").text(this.lastSentence);
		jQuery(".js-sentence-mob").text(this.lastSentence);
		jQuery(".js-sentence-mob").css("opacity", 1);
		jQuery(".js-sentence").css("opacity", 1);

	}

	initButtonsBar2() {
		jQuery(".js-btn-change-sentence").on("click", jQuery.proxy(this.showSentence, this));
		jQuery(".js-share").on("click", jQuery.proxy(this.scatta, this));
		//setTimeout(jQuery.proxy(this.scatta,this),2000);
		jQuery(".js-next").on("click", jQuery.proxy(this.showNextAmbiente, this));
		jQuery(".js-prev").on("click", jQuery.proxy(this.showPrevAmbiente, this));
	}

	showNextAmbiente() {
		this.cnta = 0;
		jQuery(".js-next").css("pointer-events", "none");
		TweenMax.to(jQuery(".wrapper-ugc"), 0.5, {
			css: {
				opacity: 0
			},
			onComplete: jQuery.proxy(this.ambienteLoaded, this)
		});
		Ambienti.nextAmbiente();
		Ambienti.showAmbiente(jQuery.proxy(this.ambienteLoaded, this));

	}

	showPrevAmbiente() {
		this.cnta = 0;
		jQuery(".js-prev").css("pointer-events", "none");
		TweenMax.to(jQuery(".wrapper-ugc"), 0.5, {
			css: {
				opacity: 0
			},
			onComplete: jQuery.proxy(this.ambienteLoaded, this)

		});
		Ambienti.prevAmbiente();
		Ambienti.showAmbiente(jQuery.proxy(this.ambienteLoaded, this));
	}

	ambienteLoaded() {
		this.cnta++;
		if (this.cnta == 2) {
			Ambienti.showAmbiente();
			this.setDimensions();
			setTimeout(jQuery.proxy(this.showCurrentAmbiente, this), 100);
		}
	}

	showCurrentAmbiente() {
		TweenMax.to(jQuery(".wrapper-ugc"), 0.5, {
			css: {
				opacity: 1
			}
		});
		jQuery(".js-next").css("pointer-events", "all");
		jQuery(".js-prev").css("pointer-events", "all");
	}

	scatta() {

		jQuery(".wrapper-ugc").width(940);
		jQuery(".wrapper-ugc").height(788);

		jQuery(".js-image-base").width(940);
		jQuery(".js-image-base").height(788);
		this.setUgcDimensions();
		jQuery(".js-results").css("overflow", "visible");

		this.lVisible = true;
		if (!jQuery(".js-sentence").is(":visible")) {
			this.lVisible = false;
		}
		jQuery(".js-sentence").show();
		jQuery(".js-sentence").css("opacity", 1);

		var mcDom = jQuery(".wrapper-ugc").get(0);
		html2canvas(mcDom, {
			allowTaint: true,
			backgroundColor: "rgba(0,0,0,0)",
			onrendered: jQuery.proxy(this.onFinalRendered, this)
		});
		jQuery(".wrapper-ugc").removeAttr("style");

		this.setDimensions();
		if (!this.lVisible) {
			jQuery(".js-sentence").hide();
		}

		jQuery("body").css("overflow", "hidden");
		jQuery("html").css("overflow", "hidden");


	}

	onFinalRendered(canvas) {
		jQuery(".js-results").css("overflow", "hidden");
		//var bmp = canvas.toDataURL("image/png");
		var bmp = canvas.toDataURL("image/jpeg", 80);
		/*var test = jQuery("<img src='" + bmp + "'>");
		jQuery("body").append(test);
		jQuery("body").css("overflow", "visible");
		jQuery("html").css("overflow", "visible");*/
		//return
		let sentences = [
			"Finalmente una call con gente interessante!",
			"La mia call di oggi! Che orgoglio!",
			"Fieramente in call con un fantastico team!",
			"Oggi una bella sorpresa in teleconferenza!",
			"Ecco la video chat dei miei sogni!",
			"Eccoci tutti collegati in call! Rosicate pure!"
		];


		this.submit(bmp, this.imageShareFacebook, this.lastSentence, sentences[this.rnd(sentences.length - 1)]);
	}

	rnd(max, min) {
		if (!min) {
			min = 0;
		}
		return Math.floor(Math.random() * (max - min + 1)) + min;
	};


	submit(img, imgFacebook, frase, titolo) {
		jQuery(".preloaderApp").show();
		/*console.log(titolo);
		console.log(frase);
		console.log(img);*/
		//console.log(imgFacebook);

		// WLF TO DO
		//setTimeout(jQuery.proxy(this.submitComplete, this), 3000);
		const data = {
			Title: titolo,
			Body: frase,
			Image: img,
			Image1: imgFacebook
		}
		jQuery.ajax({
			type: "POST",
			url: "/rest/add.post",
			data: data,
			dataType: "json",
			complete: jQuery.proxy(this.onSubmitCallBack, this)
		});
	}

	onSubmitCallBack(response) {
		//document.location.href = "/grazie.html";
		//return
		//jQuery(".preloaderApp").hide();
		// WLF TO DO
		// document.location.href = "grazie.html";
		let rs = response ? response.responseJSON : null;
		if ((rs && rs.Success)) {
			document.location.href = "/grazie/" + rs.Uid;
			//document.location.href = "/grazie.html?" + rs.Uid;
		} else {
			// TO DO FAIL
			//jQuery(".wrapper-error").css("opacity", 1);
			jQuery(".preloaderApp").hide();
			Alert.show({
				title: "Attenzione!",
				text: "Non sono riuscito a creare l'immagine. Controlla la connessione o riprova più tardi.",
				buttonOk: "Okay"
			})

		}
	}


	checkGrazie() {
		let url = document.location.href;
		if (url.indexOf("#ugc") >= 0) {
			jQuery(".js-caso1").show();
		} else {
			jQuery(".js-caso0").show();
			console.log(jQuery(".js-caso0"))
		}

	}
}

Main.SOUND = true;
//Main.SOUND = false;