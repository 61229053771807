export default class Provider {
	constructor(lng = "all") {
		this.lng = lng;
		this.cookieName = this.lng + "_Fellows";
		this.data = null;
		this.cats = new Array();
		this.templates = [
			[2, 1, 1, 1, 0],
			[2, 1, 1, 0, 1],
			[2, 1, 0, 1, 1],
			[2, 0, 1, 1, 1],
			[1, 1, 1, 1, 1]
		]
		this.templates = [
			[1, 1, 1, 1, 1]
		];
	}

	init(data) {
		this.data = data;
		let a = this.data.json;
		for (let x = 0; x < a.length; x++) {
			let lAggiungi = true;
			if (this.lng != "all") {
				if (a[x].lng != this.lng) {
					lAggiungi = false;
				}
			}
			if (lAggiungi) {
				this.aggiungi(a[x]);
			}
		}
		this.cats.sort(function (a, b) {
			if (a.quanti > b.quanti) {
				return -1;
			}
			if (a.quanti < b.quanti) {
				return 1;
			}
			return 0;
		});

		if (!jQuery.cookie(this.cookieName)) {
			jQuery.cookie(this.cookieName, JSON.stringify({
				cats: []
			}), {
				expires: 30,
				path: '/'
			});
		}
		//console.log(this.cats)
	}

	aggiungi(entry) {
		let lFound = false;
		for (let x = 0; x < this.cats.length; x++) {
			if (this.cats[x].cat == entry.cat) {
				this.cats[x].entries.push(entry);
				this.cats[x].quanti++;
				lFound = true;
				break;
			}
		}
		if (!lFound) {
			this.cats.push({
				cat: entry.cat,
				entries: [entry],
				quanti: 1
			});
		}
	}

	getChatFellows() {
		let ret = [];
		let template = this.templates[this.rnd(this.templates.length - 1)];
		for (let x = 0; x < template.length; x++) {
			ret = ret.concat(this.getFellows(this.cats[x], template[x], []));
		}
		return ret;
	}

	getChatFellow(lastid, cat, ids, lNext) {
		//console.log(lNext);
		/*let o = null;
		if (this.rnd(1) == 0) {
			for (let x = 0; x < this.cats.length; x++) {
				if (this.cats[x].cat == cat) {
					o = this.cats[x];
					break;
				}
			}
		} else {
			o = this.cats[this.rnd(this.cats.length - 1)];
		}*/
		let o = null;

		return this.getNextFellow(lastid, cat, ids, lNext);



		/*if (o) {
			let a = this.getFellows(o, 1, ids);
			return a[0];
		}
		return null;*/
	}

	getNextFellow(lastid, cat, esclusi, lNext) {
		let a = this.data.json;

		let index = -1;
		for (let x = 0; x < a.length; x++) {
			if (a[x].id == lastid) {
				index = x;
				break;
			}
		}

		if (lNext) {
			index++;
			if (index >= a.length) {
				index = 0;
			}
		} else {
			index--;
			if (index < 0) {
				index = a.length - 1;
			}
		}

		let lCondExit = false;
		let chk = 0;
		while (!lCondExit) {
			chk++;
			if (lNext) {
				if (index >= a.length) {
					index = 0;
				}
			} else {
				if (index < 0) {
					index = a.length - 1;
				}
			}
			if (esclusi.indexOf(a[index].id) < 0) {

				lCondExit = true;
			} else {
				//console.log("SKIPPO")
				if (lNext) {
					index++;
				} else {
					index--;
				}
			}
			if (chk > 100) {
				lCondExit = true;
			}
		}

		//console.log(a[index].id);
		return a[index];
	}
	getCategoria(cat) {
		let o = null;
		for (let x = 0; x < this.cats.length; x++) {
			if (this.cats[x].cat == cat) {
				o = this.cats[x];
				break;
			}
		}
		if (!o) {
			o = this.cats[0];
		}

		return o;

	}

	getFellows(o, quanti, esclusi) {

		let ret = [];
		for (let x = 0; x < quanti; x++) {
			ret.push(this.getFellow(o, esclusi));
		}
		return ret;
	}

	getFellow(o, esclusi) {
		let a = o.entries;
		let lCondExit = false;
		let cnt = 0;
		let fellow
		while (!lCondExit) {
			fellow = a[this.rnd(a.length - 1)];
			if (!this.giaUscito(fellow, a.length, esclusi)) {
				lCondExit = true;
			}
			if (esclusi.indexOf(fellow.id) >= 0) {
				lCondExit = false;
			}
			cnt++;
			if (cnt > 10) {
				lCondExit = true;
			}
		}
		return fellow;
	}

	giaUscito(fellow, totali) {
		let ret = true;
		let usciti = JSON.parse(jQuery.cookie(this.cookieName));
		let a = usciti.cats;
		let lCat = false;
		let index = -1;
		for (let x = 0; x < a.length; x++) {
			if (a[x].cat == fellow.cat) {
				index = x;
				lCat = true;
				break;
			}
		}
		if (!lCat) {
			ret = false;
			usciti.cats.push({
				cat: fellow.cat,
				entries: [fellow.id]
			});
		} else {
			let cat = a[index];
			let uscitiCat = cat.entries
			let lUscito = false;
			for (let x = 0; x < uscitiCat.length; x++) {
				if (uscitiCat[x] == fellow.id) {
					lUscito = true;
					break;
				}
			}
			if (!lUscito) {
				ret = false;
				cat.entries.push(fellow.id);
				if (cat.entries.length > Math.floor(totali * 0.7)) {
					cat.entries.shift();
				}
			}
		}
		this.updateCookie(usciti);
		//console.log(usciti);
		return ret;
	}

	updateCookie(usciti) {
		jQuery.cookie(this.cookieName, JSON.stringify(usciti), {
			expires: 30,
			path: '/'
		});
	}

	rnd(max, min) {
		if (!min) {
			min = 0;
		}
		return Math.floor(Math.random() * (max - min + 1)) + min;
	};


	getEmotionalSentence() {
		let ret = "";
		let a = this.data.frasi;
		for (let x = 0; x < a.length; x++) {
			let piece = a[x][this.rnd(a[x].length - 1)];
			ret += piece + " ";
		}
		return jQuery.trim(ret);
	}


}