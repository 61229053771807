export default class Ambienti{
	static settaAmbiente(){
		let quale = Ambienti.rnd(Ambienti.QUANTI-1);
		Ambienti.corrente = quale;
		Ambienti.showAmbiente();
	}

	static showAmbiente(callback){
		let img = new Image();
		let path = "./assets/ambienti/"+Ambienti.corrente+".png"
		//let path = "http://www.easyapps.it/tmp/ambienti/"+Ambienti.corrente+".png"
		img.onload = jQuery.proxy(Ambienti.ambienteLoaded,Ambienti,path,callback,img);
		img.src = path; 
	}

	static ambienteLoaded(path,callback,img){
		if (callback){
			callback.call(path);
		} else {
			jQuery(".js-ambiente").attr("src",img.src);
		}
	}
	static getDimAndPos(){
		return Ambienti.DIM_AND_POS[Ambienti.corrente];
	}
	static rnd(max, min) {
		if (!min) {
			min = 0;
		}
		return Math.floor(Math.random() * (max - min + 1)) + min;
	};

	static nextAmbiente(){
		Ambienti.corrente++;
		if (Ambienti.corrente >= Ambienti.QUANTI){
			Ambienti.corrente = 0;
		}
	}
	static prevAmbiente(){
		Ambienti.corrente--;
		if (Ambienti.corrente <0){
			Ambienti.corrente = Ambienti.QUANTI-1;
		}
	}

}

Ambienti.corrente = -1;
Ambienti.QUANTI = 7;
Ambienti.DIM_AND_POS = [
	{ // 0
		left: 202/940,
		top: 274/788,
		width: 537/940,
		height: 336/788
	},
	{ // 1
		left: 139/940,		
		top: 371/788,
		width: 463/940,
		height: 290/788
	},
	{ // 2
		left: 276/940,		
		top: 239/788,
		width: 370/940,
		height: 231/788
	},
	{ // 3
		left: 159/940,		
		top: 249/788,
		width: 507/940,
		height: 316/788
	},
	{ // 4
		left: 256/940,		
		top: 319/788,
		width: 394/940,
		height: 246/788
	},
	{ // 5
		left: 205/940,		
		top: 297/788,
		width: 416/940,
		height: 260/788
	},
	{ // 6
		left: 297/940,		
		top: 270/788,
		width: 489/940,
		height: 305/788
	}
]


