export default class Fellow {
	constructor(target0, target1, data, delay, quale,callback) {
		this.target0 = target0;
		this.target1 = target1;
		this.data = data;
		this.delay = delay
		this.callback = callback;
		this.quale = quale;
		//console.log(data);
	}

	init() {
		setTimeout(jQuery.proxy(this.doInit, this), this.delay * Fellow.WAIT);
	}
	doInit() {
		let mc = this.target0;
		jQuery(".js-place-holder", mc).hide();
		jQuery(".js-connect", mc).show();
		jQuery(".js-name", mc).show();
		//jQuery(".js-name", mc).html(this.data.nick);
		jQuery(".js-into-name",jQuery(".js-name", mc)).html(this.data.nick);
		jQuery(".js-name", this.target1).show();
		jQuery(".js-name", this.target1).html(this.data.nick);

		let img = new Image();
		img.onload = jQuery.proxy(this.imageLoaded, this);
		img.src = "assets/images/" + this.data.img;
	}
	dispose(){
		jQuery(".js-name", this.target0).hide();
		jQuery(".js-place-holder", this.target0).show();
		//jQuery(".js-name", this.target0).off("click");
		jQuery(".js-fel-prev", jQuery(".js-name", this.target0)).off("click");
		jQuery(".js-fel-next", jQuery(".js-name", this.target0)).off("click");

		jQuery(".js-connect", this.target0).hide();
		jQuery(".js-name", this.target0).addClass("name-red");
		this.target0.css("background-image", "none");
	}

	imageLoaded(e) {
		setTimeout(jQuery.proxy(this.showImage, this), Fellow.APPEAR);
	}
	clickable(){
		jQuery(".js-name", this.target0).css("cursor", "pointer");

		//jQuery(".js-name", this.target0).off("click");
		//jQuery(".js-name", this.target0).on("click", jQuery.proxy(this.change, this));

		jQuery(".js-fel-prev",jQuery(".js-name", this.target0)).off("click");
		jQuery(".js-fel-prev", jQuery(".js-name", this.target0)).on("click", jQuery.proxy(this.change, this,false));

		jQuery(".js-fel-next", jQuery(".js-name", this.target0)).off("click");
		jQuery(".js-fel-next", jQuery(".js-name", this.target0)).on("click", jQuery.proxy(this.change, this,true));
	}
	showImage() {
		jQuery(".js-connect", this.target0).hide();
		jQuery(".js-name", this.target0).removeClass("name-red");
		this.target0.css("background-image", "url(assets/images/" + this.data.img + ")");
		this.target1.css("background-image", "url(assets/images/" + this.data.img + ")");
		jQuery(".js-name", this.target0).css("cursor","default");
		this.callback.call(null,{type:"ready"});

	}
	change(lNext){
		this.callback.call(null,{type:"change",fellow:this,lNext:lNext});
	}
}

Fellow.WAIT = 1000;
Fellow.APPEAR = 1500;

//Fellow.WAIT = 100;
//Fellow.APPEAR = 100;